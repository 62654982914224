@import "../../../../public/mixins/mixins.scss";

.hospitalWrapper {
  margin: 0px 5px;

  .hospitalContainer {
    width: 355px;
    @media (max-width: 465px) {
      width: 315px;
    }

    @media (max-width: 420px) {
      width: 265px;
    }
    height: 512px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    .hospitalInfoContainer {
      position: absolute;
      height: 100%;
      padding: 10px;
      text-align: center;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 109, 175, 1) 100%
      );

      .hospitalInfo {
        padding: 280px 10px 50px 10px;
        .hospitalName {
          @include font(18px, 700, #fff, 0.72px);
        }
        .hospitalPlace {
          @include font(14px, 600, #fff, 0.56px);
        }
        .hospitalAddress {
          margin-top: 15px;
          @include font(14px, 500, #fff, 0.56px, 30px);
        }
        .quoatationBtn {
          display: inline-block;
          @include buttonType1(10px 38px, 10px, 14px, 600, #006daf);
        }
      }
    }
  }
}
